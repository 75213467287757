.ant-form {
    overflow: hidden;
    font-size: 1.6rem;

    input.ant-input,
    .ant-select-selector,
    .ant-picker {
        display: block;
        border-radius: 0;
        border-width: 0 !important;
        box-sizing: border-box;
        padding: 2rem !important;
        border-width: 0;
        min-height: 6.2rem;
        .ant-select-selection-search {
            margin-top: 1.5rem;
        }
    }

    .ant-input-affix-wrapper,
    .ant-input-focused, .ant-input:focus,
    .ant-picker-focused, .ant-picked:focus,
    .ant-select-focused, .ant-select .ant-select-selector {
        border-color: none !important;
        border: 0 !important;
        box-shadow: none !important;
    }
    .ant-form-item-label > label {
        font-family: $giloy;
        font-style: normal;
        font-weight: 800;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 1rem 0;
    }
    .ant-select-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .ant-select-arrow {
        margin-right: 0.75rem;

        svg {
            font-size: 1.6rem;
            transform: scaleX(0.8);
            fill: rgba(0,0,0,0.5);
        }
    }

    .ant-form-item-has-error {
        label {
            color: $error;
        }
        .ant-form-item-control-input {
            border: 0.2rem solid $error;
        }
        .ant-form-item-explain-error {
            [role="alert"] {
                font-size: 1.8rem;
                margin: 1.5rem 0;
                &:before {
                    content: "";
                    background-image: url(../images/error.svg);
                    width: 1.9rem;
                    height: 1.6rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    display: inline-block;
                    margin-right: 1.5rem;
                    top: -0.2rem;
                    position: relative;
                    vertical-align: middle;
                }
            }
        }
    }
}

.ant-btn.ant-btn-primary,
button.ant-btn.ant-btn-primary {
    font-family: $mainfont;
    border-width: 0 !important;
    background-color: $primary;
    font-style: normal;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 112%;
    // min-width: 40rem;
    max-width: 100%;
    padding: 3rem 10rem;
    box-sizing: border-box;
    display: block;
    margin: 6rem auto 0 auto;
    box-shadow: none;
    text-shadow: none;
    height: auto;
    white-space: nowrap;
    color: white;
    transition: 0.2s ease background-color, 0.2s ease color;

    &:hover,
    &:focus,
    &:active {
        background-color: white;
        color: $primary;
        cursor: pointer;
    }
    @media only screen and (max-width: 600px) {
        min-width: unset;
    }
}
