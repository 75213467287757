@import "./_variables.scss";
@import "./Animation.scss";
@import "./Form.scss";
@import "./components/index.scss";
@import "./Type.scss";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

/*
Document
*/
body {
    font-size: 1.6rem;
    font-family: $mainfont;
    max-width: 100vw;
    overflow-x: hidden;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
}
@media only screen and (min-width: 1024px) {
    .centered {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 3rem;
    }
}

.panel {
    border-radius: 2rem;
    background-color: $light-grey;
    max-width: 102.4rem;
    display: block;
    margin: 20vh auto 5.2rem auto;
    padding: 13rem 19rem 7.5rem 19rem;
    box-sizing: border-box;
    position: relative;
    transform: all 0.5s;

    @media only screen and (max-width: 600px) {
        padding: 7rem 3rem 0 3rem;
        margin: 8rem 3rem 3rem 3rem;
    }


    &.inline {
        display: block;

        @media only screen and (min-width: 1024px) {
            display: inline-block;
            text-align: center;
            width: 20%;
            min-width: 48rem;
            margin: 20vh 0;
            padding: 10rem 7.5rem 7.5rem;

            p {
                max-width: 35rem;
                margin: 0 auto;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}

#root {
    #debug {
        position: absolute;
    }

    .ant-layout {
        background-color: white;

        .App-logo {
            display: block;
            margin: auto;
            margin-bottom: 8rem;
            max-width: 100%;
        }
        
        .notification {
            max-width: 102.4rem;
            display: block;
            margin: 0 auto 20vh auto;
            padding: 0 19rem 13rem 19rem;
            box-sizing: border-box;
            position: relative;
            transform: all 0.5s;
            @media only screen and (max-width: 600px) {
                padding: 0 3rem 6rem 3rem;
                margin: 0 3rem 6rem 3rem;
            }
        }
    }
}