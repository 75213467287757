h1,
.h1 {
    font-style: normal;
}

h2,
.h2 {
    font-style: normal;
}

h3,
.h3 {
    font-style: normal;
}

h4,
.h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 3.6rem;
    line-height: 4.2rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: $primary;
}

h5,
.h5 {
    font-style: normal;
}

h6,
.h6 {
    font-style: normal;
}