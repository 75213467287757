// TODO: will need to add styles for new certificate-type

.certificate-container {
    position: fixed;
    top: 0;
    z-index: -1;
    overflow: hidden;
    width: 0;
    height: 0;
}

#cert {
    width: 792px;
    height: 612px;
    background-color: white;
    position: relative;
    h1 {
        position: relative;
        font-family: $opensans;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 1.12;
        text-align: center;
        margin-bottom: 3.9rem;
        color: $primary;
    }
    p {
        position: relative;
        font-family: $opensans;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
    }
    .studentName {
        font-family: $pinyon;
        font-size: 3rem;
        line-height: 3.6rem;
        margin: 0 auto 1rem;
        text-align: center;
    }
    #certbg {
        width: 792px;
        height: 612px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    #certtext {
        position: absolute;
        top: 17rem;
        left: 50%;
        transform: translateX(-50%);
        min-width: 55rem;
        p {
            font-size: 1rem;
        }
    }
    #certfooter {
        position: absolute;
        width: 100%;
        top: 43rem;
        display: flex;
        p {
            font-style: italic;
        }
        div {
            width: 50%;
            text-align: center;
            position: relative;
        }
    }
    .signature {
        // width: 16.2rem;
        // height: 2.9rem;
        width: 15.8rem;
        height: 7rem;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

#yos-certificate {
    img.badge {
        position: absolute;
        top: 0.9rem;
        left: calc(50% - 5.4rem);
        height: 10.8rem;
        width: 10.8rem;
    }

    .badgeContainer{
        position: absolute;
        top: 3.6rem;
        left: 50%;

        img {
            &:first-of-type {
                height: 3.6rem;
                width: 3.6rem;
            }
            &.icon {
                height: 1.8rem;
                width: 1.8rem;
            }
        }
    }
    #certtext {
        top: 10rem;

        &.is-over-20 {
            top: 13.6rem;

            h1 {
                margin-bottom: 1.9rem;
            }
        }
    }
    #certfooter {
        max-width: 57.4rem;
        margin-left: calc((100% - 57.4rem)/2);
        margin-right: calc((100% - 57.4rem)/2);
        display: flex;
        flex-direction: row;
        top: 75%;
        justify-content: space-evenly;
        align-items: flex-end;

        div {
            width: 33%;

            .conseillogo {
                padding-bottom: 1.5rem;
            }
        }

        .signature {
            width: 15.8rem;
            height: 7rem;
            position: absolute;
            bottom: 75%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}