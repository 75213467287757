.badgeContainer {
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    
    .icon {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
    }
}